/* eslint-disable react/no-this-in-sfc */
import React from "react";
import { Link } from "react-router-dom";

import { Container } from "./styles";
import Header from "../../components/Header";

export default function Home() {
  return (
    <Container>
      <Header />

      <h1>Seja bem vindo ao Signature Maker!</h1>

      <div className="options">
        <h3>Escolha para qual empresa deseja fazer a assinatura:</h3>

        <div className="cards">
          <Link to="/webtrip">
            <a className="card">
              <img
                src="http://webtrip.tur.br/ass/Logo-WebTrip-Web-Preto.svg"
                alt="Logo WebTrip"
              />
            </a>
          </Link>

          <Link to="/mindtrip">
            <a className="card">
              <img
                src="https://mindtrip.com.br/assets/images/logo-mindtrip-1-256x128.png"
                alt="Logo Mindtrip"
              />
            </a>
          </Link>

          <Link to="/corptrip">
            <a className="card">
              <img
                src="https://www.corptrip.com.br/assets/images/logobaixaresoluo-png-1-288x144.png"
                alt="Logo CorpTrip"
              />
            </a>
          </Link>
        </div>
      </div>
    </Container>
  );
}
