import styled from "styled-components";

export const Container = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginCard = styled.div`
  background-color: #fff;
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  max-width: 400px;
  min-width: 400px;
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  height: 100%;

  padding: 24px;
  text-align: center;

  h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 8px;
    text-align: left;
  }

  button {
    margin-bottom: 16px;
  }
`;
