/* eslint-disable react/no-this-in-sfc */
import React, { useRef, useCallback } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { FormHandles } from "@unform/core";
import { FiUser, FiLock } from "react-icons/fi";

import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

import { Container, LoginCard } from "./styles";

import Input from "../../components/Input";
import Button from "../../components/Button";

import getValidationErrors from "../../utils/getValidationErrors";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push("/dashboard");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: "error",
          title: "Erro na autenticação",
          description: "Email não cadastrado",
        });
      }
    },
    [signIn, addToast, history]
  );

  return (
    <Container>
      <LoginCard>
        <h2>Faça seu login!</h2>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <p>Email</p>
          <Input
            icon={FiUser}
            type="email"
            name="email"
            placeholder="example@example.com"
          />

          <p>Senha</p>
          <Input
            icon={FiLock}
            type="password"
            name="password"
            placeholder="Senha"
          />

          <Button type="submit">Login</Button>

          <Link to="/how-do-i-register">Como criar uma conta?</Link>
        </Form>
      </LoginCard>
    </Container>
  );
};

export default SignIn;
