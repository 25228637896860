import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  button {
    width: 100%;
    height: 56px;

    background-color: #cca75a;
    border-radius: 8px;

    color: white;
    font-weight: bold;
    font-size: 16px;

    border: 0;

    transition: background-color 200ms;
  }

  button:hover {
    background: ${darken(0.08, "#cca75a")};
  }
`;
