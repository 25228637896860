/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useRef } from "react";
import {
  FiCopy,
  FiUser,
  FiBriefcase,
  FiPhone,
  FiMail,
  FiGitMerge,
} from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useToast } from "../../hooks/toast";
import getValidationErrors from "../../utils/getValidationErrors";

import { Container } from "./styles";

import Header from "../../components/Header/index";
import Input from "../../components/Input";
import Button from "../../components/Button";
import api from "../../services/api";

interface IWebTripSignatureData {
  name: string;
  office: string;
  phone: string;
  branch: string;
  email: string;
}

export default function Webtrip() {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleGenerateSignature = useCallback(
    async (data: IWebTripSignatureData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required("Nome obrigatório"),
          office: Yup.string().required("Cargo obrigatório"),
          phone: Yup.string().required("Telefone obrigatório"),
          branch: Yup.string().required("Ramal obrigatório"),
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
        });

        await schema.validate(
          { ...data },
          {
            abortEarly: false,
          }
        );

        const createSignature = await api.post("signatures", {
          ...data,
          company: "webtrip",
        });

        console.log("createSignature");

        if (createSignature) {
          addToast({
            type: "success",
            title: "Assinatura gerada com sucesso!",
            description: "O código da assinatura está pronto para ser copiado",
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: "error",
          title: "Erro ao gerar assinatura",
          description:
            "Ocorreu um erro ao gerar essa assinatura. Confira as informações",
        });
      }
    },
    [addToast]
  );

  return (
    <Container>
      <Header />

      <Form className="form" onSubmit={handleGenerateSignature} ref={formRef}>
        <h1>Inserir dados</h1>

        <div>
          <div className="field-group">
            <div className="field">
              <p>Nome</p>
              <Input
                icon={FiUser}
                type="text"
                id="inputName"
                name="name"
                placeholder="Nome"
              />
            </div>
          </div>

          <div className="field-group second">
            <div className="field" id="job">
              <p>Cargo</p>
              <Input
                icon={FiBriefcase}
                type="text"
                name="office"
                placeholder="Cargo"
              />
            </div>
            <div className="field">
              <p>Telefone (fixo)</p>
              <Input
                name="phone"
                id="name"
                icon={FiPhone}
                type="text"
                placeholder="(00) 1234-5678"
              />
            </div>
          </div>

          <div className="field-group">
            <div className="field" id="branch">
              <p>Ramal</p>
              <Input
                icon={FiGitMerge}
                type="text"
                name="branch"
                placeholder="1234"
              />
            </div>
            <div className="field">
              <p>Email</p>
              <Input
                icon={FiMail}
                type="email"
                name="email"
                placeholder="example@mail.com"
              />
            </div>
          </div>
        </div>

        <Button type="submit">Gerar assinatura</Button>
      </Form>
      <header className="outside-title">
        <h3>Código da assinatura:</h3>
        <h3 className="copy-success" id="success-element">
          Texto copiado com sucesso!
        </h3>
      </header>
      <div className="copy-area">
        <input
          type="text"
          name="search"
          id="signature-input"
          placeholder='Preencha os campos acima e clique em "Gerar assinatura"'
        />
        <button
          type="button"
          // onClick={() => {
          //   Copy.copyThis();
          // }}
        >
          <FiCopy size={20} />
          <p>Copiar</p>
        </button>
      </div>
    </Container>
  );
}
