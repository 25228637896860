import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  max-width: 1000px;
  min-width: 1000px;
  width: 100%;
  min-height: 100vh;
  height: 100%;

  h1 {
    margin-top: 120px;
  }

  h3 {
    font-weight: 500;
  }

  .options {
    margin-top: 64px;
    text-align: center;

    .cards {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 32px;

      .card {
        background: transparent;
        border-radius: 8px;
        border: 2px solid #322153;
        margin: 0 8px;

        width: 250px;
        height: 180px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: all 0.2s;
      }

      .card:hover {
        border: 2px solid #cca75a;

        img {
          transform: scale(1.05);
          transition: all 0.2s;
        }
      }

      .card img {
        width: 200px;
        border-radius: 8px;

        transition: all 0.2s;
      }
    }
  }
`;
