import styled from "styled-components";

export const Container = styled.div`
  max-width: 1100px;
  width: 100%;

  color: #322153;

  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .links {
    display: flex;

    a,
    button {
      border: none;
      font-size: 18px;
      text-decoration: none;
      color: #322153;

      display: flex;
      align-items: center;
      margin-right: 24px;
      border-bottom: 2px solid transparent;

      transition: 0.1s;
    }

    a:hover,
    button:hover {
      border-bottom: 2px solid #322153;
    }
  }
`;
