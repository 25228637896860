import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

import { Container } from "./styles";

export default function Header() {
  const { signOut } = useAuth();

  return (
    <Container>
      <div className="links">
        <Link to="/">Home</Link>
        {/* <Link to="/tutorials">Tutoriais</Link>
        <Link to="/account">Minha conta</Link> */}
        <button type="button" onClick={signOut}>
          Sair
        </button>
      </div>
      <h2>Signature maker</h2>
    </Container>
  );
}
