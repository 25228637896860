import styled from "styled-components";
import px2vw from "../../utils/px2vw";

export const Container = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;

  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.div`
  width: ${px2vw(1000)};
  margin: 120px auto;
  background: #fff;

  padding: 24px;
  border-radius: 8px;

  .link {
    display: flex;
    align-items: center;
    color: #322153;

    p {
      margin-top: 2px;
    }

    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 8px 0;
  }
`;

export const Text = styled.div`
  p {
    line-height: 22px;
    margin: 24px 0;
  }
`;
