import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import SignIn from "../pages/SignIn";
import Dashboard from "../pages/Dashboard";
import WebTrip from "../pages/WebTrip";
import HowToCreateAccount from "../pages/HowToCreateAccount";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/webtrip" component={WebTrip} isPrivate />
    <Route path="/how-do-i-register" component={HowToCreateAccount} />
  </Switch>
);

export default Routes;
