import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #f0f0f5;
  border-radius: 10px;
  padding: 8px;
  width: 100%;

  border: 2px solid transparent;

  display: flex;
  align-items: center;

  transition: color 0.2s, border 0.2s;

  & + div {
    margin-top: 24px;
  }

  svg {
    margin: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #cca75a;
      border-color: #cca75a;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #cca14a;
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: #000;
    outline: none;
    padding: 8px;

    font-size: 16px;

    &::placeholder {
      color: #666360;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
      visibility: visible;
    }
  }
`;
