import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;
  max-width: 1000px;
  min-width: 1000px;

  min-height: 100vh;
  height: 100%;

  .form {
    background-color: white;

    margin: 80px auto 32px auto;
    padding: 64px;

    border-radius: 8px;

    max-width: 800px;
    width: 100%;
  }

  .form h1 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .form legend {
    margin-bottom: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  .form legend span {
    color: #322153;
    font-size: 14px;
  }

  .form .field {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    width: 100%;
  }

  .form .field-group {
    display: flex;
  }

  .form .field-group.second #job {
    width: 160%;
  }

  .form .field-group.third #branch {
    width: 30%;
  }

  .form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .form .field-group .field + .field {
    margin-left: 24px;
  }

  .form button {
    width: 100%;
    height: 56px;

    background-color: #cca75a;
    border-radius: 8px;

    color: white;
    font-weight: bold;
    font-size: 16px;

    border: 0;

    margin-top: 40px;

    transition: background-color 300ms;
  }

  .form button:hover {
    background-color: #dfb967;
  }

  .items-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  .items-grid li {
    background-color: #f5f5f5;
    list-style: none;

    border: 2px solid #f5f5f5;
    border-radius: 8px;
    height: 180px;

    padding: 32px 24px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    text-align: center;

    cursor: pointer;
  }

  .items-grid li span {
    margin-top: 12px;
    flex: 1;

    display: flex;
    align-items: center;

    color: #322153;
  }

  .items-grid li.selected {
    background: #e1faec;
    border: 2px solid #34cb79;
  }

  .items-grid li img,
  .items-grid li span {
    pointer-events: none;
  }

  .outside-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 800px;
    width: 100%;

    margin: 0 auto;
    margin-bottom: 8px;
  }

  legend h3 {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 8px;
  }

  .copy-area {
    display: flex;
    justify-content: center;

    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  .copy-area input {
    flex: 1;
    width: 100%;

    background-color: white;
    border-radius: 8px 0 0 8px;
    border: 0;
    padding: 16px 24px;

    font-size: 16px;
    color: #6c6c80;
  }

  .copy-area button {
    width: 72px;
    height: 72px;

    background-color: #cca75a;

    border: 0;
    border-radius: 0 8px 8px 0;

    color: white;

    outline: none;

    transition: 200ms;
  }

  .copy-area button:hover {
    background-color: #dfb967;
  }

  .copy-area button p {
    margin-top: 4px;
    font-weight: bold;
  }

  .copy-success {
    display: none;
  }

  .copy-success.active {
    display: initial;
  }

  .show-signature-content section {
    background-color: white;

    margin: 8px auto 32px auto;
    padding: 64px;

    border-radius: 8px;

    max-width: 700px;
    width: 100%;
  }
`;
