/* eslint-disable react/no-this-in-sfc */
import React from "react";

import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Container, Content, Text, Title } from "./styles";

export default function HowToCreateAccount() {
  return (
    <Container>
      <Content>
        <Link to="/">
          <div className="link">
            <FiArrowLeft size={22} color="#322153" />
            <p>Voltar</p>
          </div>
        </Link>
        <Text>
          <Title>
            <h1>Como criar uma conta no Signature Maker?</h1>
          </Title>
          <p>
            Atualmente não é possível criar uma conta de um jeito tradicional,
            apenas inserindo nome, email e senha por exemplo. Como este é um
            projeto exclusivo de algumas empresas, somente pessoas autorizadas
            têm acesso, tanto para acessarem a plataforma, quanto para criar
            usuários. Atualmente o gestor do projeto é o Guilherme Illescas,
            gerente do T.I., e quem concede a permissão para a criação de contas
            é a Cláudia Budziak, gerente do RH.
          </p>

          <p>
            Você deve solicitar à Cláudia uma conta. Com a aprovação dela, será
            passado ao Guilhemre Illescas, uma solicitação de criação de conta
            dentro da plataforma. Então, a conta será criada e o acesso estará
            liberado.
          </p>
        </Text>
      </Content>
    </Container>
  );
}
